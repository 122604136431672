var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-4"
  }, [_c('i', {
    staticClass: "fa fa-backward"
  }), _c('router-link', {
    attrs: {
      "to": {
        name: _vm.DetailItems.name,
        params: {
          id: _vm.item.id
        }
      }
    }
  }, [_vm._v(" Detail Item ")])], 1)], 1), _c('b-card', {
    attrs: {
      "header": 'Cover - ' + _vm.item.name,
      "header-bg-variant": "primary",
      "header-tag": "h5",
      "header-class": "font-weight-bold"
    }
  }, [_c('div', [_c('b-form-file', {
    ref: "fileInput",
    staticClass: "mb-3",
    attrs: {
      "placeholder": "Choose a file or drop it here...",
      "accept": ".jpg",
      "drop-placeholder": "Drop file here..."
    },
    on: {
      "change": _vm.handleFileUpload
    }
  }), _vm._l(_vm.imagePreviews, function (preview, index) {
    return _c('div', {
      key: index,
      staticClass: "cover_image_update"
    }, [_c('img', {
      staticClass: "shadow rounded",
      attrs: {
        "src": preview.file_cover,
        "alt": preview.alt
      }
    }), _c('div', {
      staticClass: "content-image-cover"
    }, [preview.alt === 178 ? _c('div', [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v("image normal")]), _c('p', [_vm._v(_vm._s(preview.alt) + "px")])]) : _vm._e(), preview.alt === 256 ? _c('div', [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v("image highres")]), _c('p', [_vm._v(_vm._s(preview.alt) + "px")])]) : _vm._e(), preview.alt === 380 ? _c('div', [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v("image highres")]), _c('p', [_vm._v(_vm._s(preview.alt) + "px")])]) : _vm._e(), preview.alt === 760 ? _c('div', [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v("image highres")]), _c('p', [_vm._v(_vm._s(preview.alt) + "px")])]) : _vm._e()]), _c('b-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('hr')])], 1);
  }), _vm.imagePreviews.length > 0 ? _c('div', {
    staticClass: "button-upload"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        return _vm.onUploadCover(_vm.imagePreviews);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-upload"
  }), _vm._v(" upload ")]), _c('b-button', {
    attrs: {
      "variant": "danger",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        return _vm.onCancelUpload();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-times"
  }), _vm._v(" Cancel ")])], 1) : _vm._e()], 2), _c('div', {
    class: {
      'd-none': _vm.imagePreviews.length > 0
    }
  }, _vm._l(_vm.BUCKET_COVER_SCALE_VALUE, function (scale) {
    return _c('b-row', {
      key: scale,
      staticClass: "p-2"
    }, [_c('b-col', {
      attrs: {
        "md": "3 text-center"
      }
    }, [_c('a', {
      attrs: {
        "href": _vm.basePathCover + _vm.removeBucket(_vm.item[scale]),
        "target": "_blank"
      }
    }, [_c('b-card-img', {
      staticClass: "img-cover shadow rounded",
      attrs: {
        "src": _vm.basePathCover + _vm.removeBucket(_vm.item[scale]) + '?' + _vm.now,
        "alt": 'cover-' + scale
      }
    }), _c('br'), _c('small', [_vm._v(" " + _vm._s(scale === null || scale === void 0 ? void 0 : scale.replaceAll('_', ' ')) + " "), _c('br'), _c('dfn', [_vm._v(_vm._s(_vm.PIXEL[scale]) + " px , " + _vm._s(_vm.DPI) + " DPI")])])], 1)]), _c('b-col', {
      attrs: {
        "md": "8",
        "lg": "6"
      }
    }), _c('b-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('hr')])], 1);
  }), 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }