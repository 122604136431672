<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <b-row>
      <b-col class="mt-4">
        <i class="fa fa-backward"></i>
        <router-link :to="{ name: DetailItems.name, params: { id: item.id } }">
          Detail Item
        </router-link>
      </b-col>
    </b-row>
    <b-card :header="'Cover - ' + item.name" header-bg-variant="primary" header-tag="h5"
      header-class="font-weight-bold">

      <div>
        <b-form-file class="mb-3" ref="fileInput" @change="handleFileUpload"
          placeholder="Choose a file or drop it here..." accept=".jpg" drop-placeholder="Drop file here...">
        </b-form-file>
        <div class="cover_image_update" v-for="(preview, index) in imagePreviews" :key="index">
          <img class="shadow rounded" :src="preview.file_cover" :alt="preview.alt">
          <div class="content-image-cover">
            <div v-if="preview.alt === 178">
              <p class="mb-0">image normal</p>
              <p>{{ preview.alt }}px</p>
            </div>
            <div v-if="preview.alt === 256">
              <p class="mb-0">image highres</p>
              <p>{{ preview.alt }}px</p>
            </div>
            <div v-if="preview.alt === 380">
              <p class="mb-0">image highres</p>
              <p>{{ preview.alt }}px</p>
            </div>
            <div v-if="preview.alt === 760">
              <p class="mb-0">image highres</p>
              <p>{{ preview.alt }}px</p>
            </div>
          </div>
          <b-col cols="12">
            <hr>
          </b-col>
        </div>
        <div class="button-upload" v-if="imagePreviews.length > 0">
          <b-button class="mr-2" variant="primary" size="sm" @click="onUploadCover(imagePreviews)">
            <i class="fa fa-upload"></i> upload
          </b-button>
          <b-button variant="danger" size="sm" @click="onCancelUpload()">
            <i class="fa fa-times"></i> Cancel
          </b-button>
        </div>
      </div>
      <div :class="{ 'd-none': imagePreviews.length > 0 }">
        <b-row class="p-2" v-for="scale in BUCKET_COVER_SCALE_VALUE" :key="scale">
          <b-col md="3 text-center">
            <a :href="basePathCover + removeBucket(item[scale])" target="_blank">
              <b-card-img :src="basePathCover + removeBucket(item[scale]) + '?' + now" :alt="'cover-' + scale"
                class="img-cover shadow rounded">
              </b-card-img>
              <br>
              <small>
                {{ scale?.replaceAll('_', ' ') }} <br>
                <dfn>{{ PIXEL[scale] }} px , {{ DPI }} DPI</dfn>
              </small>
            </a>
          </b-col>
          <b-col md="8" lg="6">
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
      </div>

    </b-card>
  </b-overlay>
</template>

<script>
import {
  mapState,
  mapActions
} from "vuex";
import {
  DetailItems
} from '../../../router/content';
import constant from "../../../store/constant";

const BUCKET_COVER_SCALE = {
  THUMB_IMAGE_NORMAL: 'thumb_image_normal',
  THUMB_IMAGE_HIGHRES: 'thumb_image_highres',
  IMAGE_NORMAL: 'image_normal',
  IMAGE_HIGHRES: 'image_highres',
};

export default {
  name: "ebookCoverEdit",
  data() {
    return {
      item_id: null,
      now: +new Date(),
      DetailItems,
      basePathCover: constant.URL_STATIC.EBOOK_COVER,
      BUCKET_COVER_SCALE,
      imagePreviewUrl: null,
      imagePreviews: [],
      imageSizes: [178, 256, 380, 760],
      BUCKET_COVER_SCALE_VALUE: Object.values(BUCKET_COVER_SCALE),
      file: {},
      DPI: 24,
      PIXEL: {
        'thumb_image_normal': 178,
        'thumb_image_highres': 256,
        'image_normal': 380,
        'image_highres': 760,
      }
    }
  },
  mounted() {
    let id = this.$route.params.id
    this.actionGetItem(id);
    this.item_id = id;
  },
  computed: {
    ...mapState({
      item: (state) => state.ebooks.item,
      isLoading: (state) => state.ebooks.isLoading,
    }),
  },
  methods: {
    ...mapActions('ebooks', ['fetchEbookById']),
    ...mapActions('items', ['uploadCover']),
    actionGetItem(id) {
      let payload = {
        id: id
      };
      this.fetchEbookById(payload)
    },
    handleFileUpload(event) {
      const files = event.target.files;
      if (files.length > 0) {
        this.imagePreviews = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileType = file.type;
          const fileSize = file.size / 1024 / 1024;

          if (!fileType.match(/image\/(jpg|jpeg|png)/)) {
            this.messageAlert('error', `Only JPG, JPEG, and PNG files.`);
            this.$refs.fileInput.reset();
            return;
          }

          if (fileSize > 10) {
            this.messageAlert('error', `File size should be between 1MB and 10MB.`)
            this.$refs.fileInput.reset();
            return;
          }

          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
              this.imageSizes.forEach((size) => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const aspectRatio = img.width / img.height;
                const canvasWidth = size;
                const canvasHeight = size / aspectRatio;
                canvas.width = canvasWidth;
                canvas.height = canvasHeight;
                ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
                const blob = this.dataURLToBlob(canvas.toDataURL('image/png'));
                this.imagePreviews.push({
                  file_cover: URL.createObjectURL(blob),
                  alt: parseInt(size),
                  file, 
                  bucket: this.BUCKET_COVER_SCALE_VALUE,
                });
              });
            };
            img.src = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      }
    },

    async onUploadCover(param) {
      this.$store.commit(`ebooks/setLoading`, true);
      const payload = {
        item_id:this.item_id,
        file_cover:param[0].file
      };
      const response = await this.uploadCover(payload);
      if (response.status == 200) {
        this.messageAlert('success', `upload success`)
         this.actionGetItem(this.item_id);
         this.file = {};
         this.onCancelUpload()
       }
       else {
         this.$store.commit(`ebooks/setLoading`, false)
         this.messageAlert('error', `${response.status ?? '-'} : ${response.statusText}`)
       }
       this.now = +new Date();
    },
    
    onCancelUpload() {
      this.imagePreviews = [];
      this.$refs.fileInput.reset();
    },
    dataURLToBlob(dataURL) {
      const arr = dataURL.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },

    removeBucket(str) {
      if (str && str.substring(0, 6) == "images") {
        return str.substring(9);
      } else {
        return str ?? 'default.png';
      }
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },

  },
  filters: {
    formatKB(val) {
      return `${(val / 1000).toFixed(2)} KB`;
    },
  },
};
</script>

<style lang="css" scoped>
.img-cover {
  max-width: 200px;
  max-height: auto;
}

.cover_image_update {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  text-align: center;
}

.button-upload {
  display: flex;
  justify-content: center;
}
</style>
